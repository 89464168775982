import axios from "./axiosConfig.js";
import { getUserByUsername } from "./userApi.js";

const REFERRALS_ENDPOINT = "referrals/";

export const createNewReferral = async (referrerId: string, referreeId: string) => {
  const response = await axios.post(REFERRALS_ENDPOINT, {
    referrerId,
    referreeId
  })
}


export const getReferralsByReferrer = async (referrerId: string) => {
    const response = await axios.get(REFERRALS_ENDPOINT + referrerId );
    if (response.status === 200) {
        return response.data;
    } else {
        throw new Error(response.statusText);
    }
    }