import { styled } from "@stitches/react";
import { useState } from "react";
import { UserProfile } from "~/api/userApi";
import { ForgotPasswordForm } from "~/ui/components/auth/ForgotPassword";
import ResetPasswordPage from "~/ui/components/auth/ResetPassword";
import { SignupForm } from "~/ui/components/auth/SignUp";
import { SigninForm } from "~/ui/components/auth/Signin";
import SignoutPage from "~/ui/components/auth/Signout";

export enum AuthFormState {
  LOGIN,
  REGISTER,
  FORGOTPASSWORD,
  RESETPASSWORD,
  SIGNOUT,
}

export const AuthPage = ({
  state,
  onlyForms,
  isInBookingFlow,
  price,
  hostUserName,
  onSubmitSignIn,
}: {
  state: AuthFormState;
  onlyForms?: boolean;
  isInBookingFlow?: boolean;
  price?: number;
  hostUserName?: string;
  onSubmitSignIn?: Function;
}) => {
  const [currState, setCurrState] = useState<AuthFormState>(state);
  const allForms = (
    <>
      {currState === AuthFormState.LOGIN ? (
        <SigninForm
          setAuthState={setCurrState}
          shouldRedirectAfterSignIn={!isInBookingFlow}
          isInBookingFlow={isInBookingFlow}
          onSubmitSignIn={onSubmitSignIn}
        />
      ) : currState === AuthFormState.REGISTER ? (
        <SignupForm
          setAuthState={setCurrState}
          shouldRedirectAfterSignIn={!isInBookingFlow}
          isInBookingFlow={isInBookingFlow}
          price={price}
          hostUserName={hostUserName}
          onSurveySubmit={onSubmitSignIn}
        />
      ) : currState === AuthFormState.FORGOTPASSWORD ? (
        <ForgotPasswordForm setAuthState={setCurrState} />
      ) : currState === AuthFormState.SIGNOUT ? (
        <SignoutPage />
      ) : currState === AuthFormState.RESETPASSWORD ? (
        <ResetPasswordPage />
      ) : null}
    </>
  );

  return onlyForms ? allForms : <StyledFormPage>{allForms}</StyledFormPage>;
};

export const StyledFormPage = styled("div", {
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "flex-start",
  paddingTop: "160px",
  paddingBottom: "60px",

  backgroundColor: "$wash",
  alignItems: "center",
  flexDirection: "column",
  "@mobile": {
    justifyContent: "flex-start",
    paddingTop: "146px",
    overflow: "scroll",
    paddingBottom: "60px",
  },
  "& .back-button": {
    position: "absolute",
    top: "20px",
    left: "20px",
    width: "20px",
    transform: "rotate(180deg)",
    cursor: "pointer",
  },
  variants: {
    loaded: {
      false: {
        display: "none",
      },
    },
  },
});

export const StyledClickableText = styled("div", {
  cursor: "pointer",
  fontSize: "12px",

  "&:hover": {
    textDecoration: "underline",
  },
  textAlign: "center",

  variants: {
    lowerText: {
      true: {
        cursor: "pointer",
        fontSize: "16px",
        marginTop: "28px",
        fontWeight: "500",
      },
    },
    link: {
      true: {
        textDecoration: "underline",
        cursor: "pointer",
        "&:hover": {
          opacity: "0.8",
        },
      },
    },
  },
});
