import { styled } from "@stitches/react";
import { position } from "html2canvas/dist/types/css/property-descriptors/position";
import React, { useEffect, useState, useContext } from "react";
import { getUserByUsername, UserProfile } from "~/api/userApi";
import {
  LoadingIndicator,
  SimpleLoadingIndicator,
} from "~/pages/loading/LoadingPage";
import { clearToast } from "~/utils/handleToasts";

enum ReferralCodeState {
  NOTHING_ENTERED,
  INPUT_OPEN,
  INVITE_FOUND,
  SEARCHING_CODE,
  NO_CODE_FOUND,
}

export const ReferralCode = ({
  referringUser,
  setReferringUser,
}: {
  referringUser: UserProfile | null;
  setReferringUser: Function;
}) => {
  const [inputState, setInputState] = useState(
    ReferralCodeState.NOTHING_ENTERED
  );
  const [referralCode, setReferralCode] = useState("");

  useEffect(() => {
    if (referringUser) {
      setInputState(ReferralCodeState.INVITE_FOUND);
      setReferralCode(referringUser.username);
    }
  }, [referringUser]);

  return (
    <StyledReferralCodeInput>
      {inputState === ReferralCodeState.NOTHING_ENTERED && (
        <div
          className="add-invite"
          onClick={() => {
            setInputState(ReferralCodeState.INPUT_OPEN);
          }}
        >
          + add invite code
        </div>
      )}
      {(inputState === ReferralCodeState.INPUT_OPEN ||
        inputState === ReferralCodeState.NO_CODE_FOUND ||
        inputState === ReferralCodeState.SEARCHING_CODE) && (
        <div className="referral-input">
          {inputState === ReferralCodeState.NO_CODE_FOUND && (
            <div className="input-error">Invalid code</div>
          )}
          <input
            type="text"
            placeholder="Invite code"
            autoFocus
            value={referralCode}
            onChange={(e) => {
              setReferralCode(e.target.value.toLowerCase());
              if (inputState === ReferralCodeState.NO_CODE_FOUND) {
                setInputState(ReferralCodeState.INPUT_OPEN);
              }
            }}
          />
          <button
            onClick={async () => {
              setInputState(ReferralCodeState.SEARCHING_CODE);
              if (referralCode.trim() === "") {
                setInputState(ReferralCodeState.NOTHING_ENTERED);
                return;
              }
              try {
                // referral codes as usernames, check to see if this is a valid username
                const referringUser = await getUserByUsername(referralCode);
                setReferringUser(referringUser);
                setInputState(ReferralCodeState.INVITE_FOUND);
              } catch (error) {
                clearToast();
                setInputState(ReferralCodeState.NO_CODE_FOUND);
              }
            }}
          >
            {inputState === ReferralCodeState.SEARCHING_CODE ? (
              <div
                style={{
                  position: "relative",
                  top: "4px",
                  left: "1px",
                  transform: "scale(0.4)",
                  opacity: 0.8,
                }}
              >
                <SimpleLoadingIndicator />
              </div>
            ) : (
              "APPLY"
            )}
          </button>
        </div>
      )}
      {inputState === ReferralCodeState.INVITE_FOUND && (
        <div
          className="invited-by"
          onClick={() => {
            setInputState(ReferralCodeState.INPUT_OPEN);
          }}
        >
          <span style={{ textDecoration: "none" }}>{`✨ `}</span>
          <span
            style={{ textDecoration: "underline" }}
          >{`${referringUser?.name} invited you`}</span>
        </div>
      )}
    </StyledReferralCodeInput>
  );
};

const StyledReferralCodeInput = styled("div", {
  height: "30px",
  display: "flex",
  width: "270px",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "0px",
  marginTop: "0px",
  fontSize: "12px",
  "& .add-invite": {
    textDecoration: "underline",
  },
  "& .add-invite, & .invited-by": {
    marginTop: "12px",
    textAlign: "center",

    cursor: "pointer",
    width: "100%",
    "&:hover": {
      opacity: 0.7,
    },
    fontSize: "11px",
  },
  "& .referral-input": {
    width: "100%",
    position: "relative",
    "& .input-error": {
      color: "#FF7D7D",
      fontSize: "9px",
      position: "absolute",
      top: "-10px",
      zIndex: 1,
    },
    "& button": {
      width: "78px",
      position: "absolute",
      right: "0px",
      marginTop: "-4px",
      fontSize: "11px",
      backgroundColor: "$gray100",
      border: "none",
      height: "24px",
      borderRadius: "16px",
      cursor: "pointer",
      "&:hover": {
        opacity: 0.7,
      },
    },
    "& input": {
      width: "calc(100% - 78px - 20px)",
      position: "absolute",
      left: "0px",
      marginRight: "20px",
      borderBottom: "1px solid $gray200",
      paddingBottom: "4px",
      fontSize: "12px",
      "&:focus": {
        outline: "none",
        borderBottom: "1px solid black",
      },
    },
  },
});
